// шаг 5
import  React from 'react'
import  styles from './Hero.module.scss'
import {motion} from "framer-motion";
import {fadeIn, staggerContainer} from "../../utils/motion";

const Hero = () => {

    return (
        <section className={`paddings ${styles.wrapper}`}>
            <motion.div
                variants={staggerContainer()}
                initial='hidden'
                whileInView='show'
                viewport={{once: false, amount: 0.25}}
                className={`innerWidth ${styles.container}`}>

                {/*upper elemetns*/}
                <div className={styles.upperElements}>
                    <motion.span
                        variants={fadeIn("right", "tween", 0.2,1)}
                        className='primaryText'>Hey There, <br/> I"m Binjan</motion.span>
                    <motion.span
                        variants={fadeIn("left", "tween", 0.2,1)}
                        className='secondaryText'> I desing beutiful simple <br/>
                    things, And i love what i do</motion.span>
                </div>

                {/*person center*/}
                <motion.div
                    variants={fadeIn("up", "tween", 0.2,1)}
                    className={styles.person}>
                    <motion.img
                        variants={fadeIn("up", "tween", 0.8,1)}
                        src="https://raw.githubusercontent.com/ZainRk/Personal-Portfolio-2-starter-pack/master/public/person.png" alt=""/>
                </motion.div>

                {/*email */}
                <a className={styles.email} href="mailto:b_m_maron@bk.ru">
                    b_m_maron@bk.ru
                </a>

                {/*lover elements*/}
                <div className={styles.lowerElements}>
                    <motion.div
                        variants={fadeIn("right", "tween", 0.2,1)}
                        className={styles.experience}>

                        <div className='primaryText'>10</div>
                        <div>
                            <div>Years</div>
                            <div>Experience</div>
                        </div>
                    </motion.div>

                    <motion.div
                        variants={fadeIn("left", "tween", 0.2,1)}
                        className={styles.secondaryText}>
                        <img src="https://raw.githubusercontent.com/ZainRk/Personal-Portfolio-2-starter-pack/e4a8440d5a8c2c0c0e53d38586565d4e518920ac/public/certificate.png" alt=""/>
                        <span>CERTIFIED PROFATIONAL</span>
                        <span>UL/UX DESIGNER</span>
                    </motion.div>
                </div>

            </motion.div>
        </section>
    )
}
export  default  Hero