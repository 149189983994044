// 6
import React  from "react";
import styles from './Expirties.module.scss'
import {projectExperience, WhatDoIHelp} from "../../utils/data";
import {motion} from "framer-motion";
import {fadeIn, staggerContainer, textVariant2} from "../../utils/motion";

const Expirties = () => {

    return (
        <motion.section 
        initial='hidden'
        whileInView='show'
        variants={staggerContainer}
        viewport={{once: false, amount: 0.25}}
        className={styles.wrapper}>

            <a className="anchor" id="portfolio"></a>

            <div className={`paddings flexCenter innerWidth xPaddings ${styles.container}`}>

                <div className={styles.leftSide}>
                    {
                        projectExperience.map((item, i) => {
                            return <motion.div
                            variants={fadeIn("right", "tween", (i+1)*0.2,1)}
                            className={styles.item} key={i}>
                                <div className="flexCenter" style={{background: item.bg}}>
                                    <item.icon size={25} color="white"/>
                                </div>

                                <div>
                                    <span>{item.name}</span>
                                    <span className="secondaryText">{item.projects} Projects</span>
                                </div>
                            </motion.div>;

                        })
                    }
                </div>

                <motion.div
                    initial="hidden"
                    whileInView="show"
                    variants={ textVariant2 }
                    className={styles.rightsSlide}>

                    <span className="primaryText text-list">What do I help?</span><br />
                       
              {WhatDoIHelp.map((paragrath, i) => {
                        return <span style={{marginTop: '20px'}} className="secondaryText" key={i}>{paragrath}</span>
                    })}

                    <div className={`flexCenter ${styles.stats}`}>
                        <div className={styles.stat}>
                            <span className="primaryText">285+</span>
                            <span className="secondaryText">Project Completed</span>
                        </div>

                        <div className={`flexCenter ${styles.stat}`}>
                            <span className="primaryText">190+</span>
                            <span className="secondaryText">Happy Clients</span>
                        </div>
                    </div>

                </motion.div>

            </div>
        </motion.section>
    )
}
export default Expirties