// 8
import  React from 'react'
import  styles from './Portfolio.module.scss'
import {motion} from "framer-motion";
import {fadeIn, staggerChildren} from "../../utils/motion";

const Portfolio = () => {
    return (
        <motion.div
            variants={staggerChildren}
            initial="hidden"
            whileInView="show"
            className={`paddings ${styles.wrapper}`}>
            <div className={`innerWidth flexCenter ${styles.container}`}>

                <div className={`flexCenter ${styles.heading}`}>
                    <div>
                        <span className="primaryText">
                            My latest Works
                        </span>
                        <p style={{marginTop: '10px'}}>
                            Perfect solution for digital experience
                        </p>
                    </div>

                    <span className="secondaryText">
                        Maron-Wilsons
                    </span>
                </div>


                {/*Image */}
                <div className={`flexCenter ${styles.showCase}`}>
                    <motion.img
                        variants={fadeIn("up", "tween", 0.5, 0.6)}
                        src="https://raw.githubusercontent.com/ZainRk/Personal-Portfolio-2-starter-pack/master/public/showCase1.png" alt=""/>
                    <motion.img
                        variants={fadeIn("up", "tween", 0.7, 0.6)}
                        src="https://raw.githubusercontent.com/ZainRk/Personal-Portfolio-2-starter-pack/master/public/showCase2.png" alt=""/>
                    <motion.img
                        variants={fadeIn("up", "tween", 0.9, 0.6)}
                        src="https://raw.githubusercontent.com/ZainRk/Personal-Portfolio-2-starter-pack/master/public/showCase3.png" alt=""/>
                </div>

            </div>
        </motion.div>
    );
}
export  default  Portfolio