//шаг 1
import  React from 'react'
import styles from './Header.module.scss'
import  {BiPhoneCall} from 'react-icons/bi'
import {motion} from 'framer-motion'
import {headerVariants} from "../../utils/motion";
import {BiMenuAltRight} from 'react-icons/bi'
import useHeaderShadow from "../../hooks/useHeaderShadow";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";

const  Header = () => {
    const [menuOpened, setMenuOpened] = React.useState(false)
    const headerShadow = useHeaderShadow() // шаг 3 нам необходимо импортировать нижний тень при скроле
    const menuRef = React.useRef()

    const getMenuStyles = (menuOpened) => {
        //getMenuStyles функция данная функция говорит если у нас маленький экран / он вернет /если menuOpened не открыта то она даст стиль 100 в правую сторону / если она открыта / то нечего не вернет
        if (document.documentElement.clientWidth <= 640) {
            return { right: !menuOpened && "-200%"};
        }
    };


    useOutsideAlerter( //11
        {
            menuRef,
            setMenuOpened
        }
    )

    return (
        <motion.div// шаг 2 мы исп анимацию framer-motion
            initial={'hidden'}
            whileInView={'show'}
            variants={headerVariants}
         className={`paddings ${styles.wrapper}`
            } style={{boxShadow: headerShadow}}>
            <div className={`flexCenter innerWidth ${styles.container}`}>
                <div className={styles.name}>
                    Binjan
                </div>

                    <ul ref={menuRef} style={getMenuStyles(menuOpened)} className={`flexCenter ${styles.menu}`}>
                        <li><a href="#expertes">Services</a></li>
                        <li><a href="#work">Experience</a></li>
                        <li><a href="#portfolio">Portfolio</a></li>
                        <li><a href="#people">Testimonials</a></li>
                        <li className={`flexCenter ${styles.phone}`}>
                            <p>+ 89188198202</p>
                            <BiPhoneCall size={"15px"}/>
                        </li>
                    </ul>

                <div onClick={() => setMenuOpened(prev => !prev)} className={styles.menuIcon}>
                    <BiMenuAltRight size={30}/>
                </div>
            </div>
        </motion.div>
    )
}


export  default Header