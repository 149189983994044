import  React from 'react'
import Header from "./componens/Header/Header";
import styles from './index.module.scss';
import './styles/global.scss'
import Hero from "./componens/Hero/Hero";
import Expirties from "./componens/Expirties/Expirties";
import Works from './componens/Works/Works';
import Portfolio from './componens/Portfolio/Portfolio';
import Peaple from "./componens/People/Peaple";
import Footer from "./componens/Footer/Footer";
const App = () => {
    return (
        <div className={`bg-primary ${styles.container}`}>
            <Header/>
            <Hero/>
            <Expirties/>
            <Works/>
            <Portfolio/>
            <Peaple/>
            <Footer/>
        </div>
    )
}
export  default  App