//10
import  React from 'react'
import styles from './Footer.module.scss'
import {motion} from "framer-motion";
import {footerVariants, staggerChildren} from "../../utils/motion";


const Footer = () => {

    return (
        <motion.div
            variants={staggerChildren}
            initial="hidden"
            whileInView="show"
            className={`paddings ${styles.wrapper}`}>

            <motion.div
                variants={footerVariants}
                className={`innerWidth flexCenter ${styles.components}`}>
                <div className={styles.left}>
                    <span className="primaryText">
                        A visual desginer dsesign <br/> for a variety
                    </span>

                    <span className="primaryText">
                    Self-Employed, Brisbane <a href="">maron@bk.ru</a>
                    </span>
                </div>

                <div className={styles.right}>
                    <div className={styles.info}>
                        <span className="secondaryText">Informarion</span>
                        <p>145 New York, FL 5467, USA</p>
                    </div>

                    <div className={styles.menu}>
                        <li>The process </li>
                        <li>submitting</li>
                        <li>appilication</li>
                        <li>quite cosy</li>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}
export  default Footer